<template>
    <div class="stepsdemo-content">
        <i class="pi pi-fw pi-ticket" />
        <p>Seat Component Content via Child Route</p>
    </div>
</template>

<script>
export default {

}
</script>
